































































































































































































































































































































































import { Empresa } from '@/core/models/geral';
import { RegistroOperacional } from '@/core/models/operacional';
import { PageBase } from '@/core/models/shared';
import { EmpresaService } from '@/core/services/geral';
import { EmbarcacaoService, RegistroOperacionalService } from '@/core/services/operacional';
import { AlertQuestion, AlertSimple, AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class DetalharRegistroOperacional extends PageBase { 

    service = new RegistroOperacionalService();
    item: RegistroOperacional = new RegistroOperacional();

    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();

    embarcacaoService: EmbarcacaoService = new EmbarcacaoService();

    overlay: boolean = false;

    id: number = 0;
    layoutId: number = 0;

    selectRadio: number = 0;

    observacaoItens: any[] = [];

    mounted() {
        this.id = Number(this.$route.params.id);
        this.layoutId = Number(this.$route.query.layout);
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;
        this.observacaoItens = [];
        this.service.ObterPorId(this.id, "Ternos.Terno, Fainas.Faina, Fainas.UnidadeMedida, Equipamentos.Equipamento, Equipamentos.Fornecedor, Validacao.Usuario, Porto").then(
            res=> {
                var resultado = res.data;

                new EmbarcacaoService().ObterPorId(resultado.embarcacaoId, "Tipo").then(
                    res => {
                        resultado.embarcacao = res.data;
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )

                resultado.ternos = resultado.ternos.sort(function(a,b){
                    if(new Date(a.data).getTime() < new Date(b.data).getTime()){
                        return -1;
                    }
                    if(new Date(a.data).getTime() > new Date(b.data).getTime()){
                        return 1;
                    }
                    if(a.terno.horaInicial < b.terno.horaInicial){
                        return -1;
                    }                        
                    if(a.terno.horaInicial > b.terno.horaInicial){
                        return 1;
                    }
                    return 0;
                });

                resultado.fainas = resultado.fainas.sort(function(a,b){
                    if(new Date(a.dataHoraInicio).getTime() < new Date(b.dataHoraInicio).getTime()){
                        return -1;
                    }
                    if(new Date(a.dataHoraInicio).getTime() > new Date(b.dataHoraInicio).getTime()){
                        return 1;
                    }
                    return 0;
                });

                resultado.equipamentos = resultado.equipamentos.sort(function(a,b){
                    if(new Date(a.dataHoraInicio).getTime() < new Date(b.dataHoraInicio).getTime()){
                        return -1;
                    }
                    if(new Date(a.dataHoraInicio).getTime() > new Date(b.dataHoraInicio).getTime()){
                        return 1;
                    }
                    return 0;
                });

                resultado.equipamentos.forEach(x => {
                    if(x.observacao){
                        var obj = {
                            numero: x.sequencia,
                            observacao: x.observacao
                        }
                        this.observacaoItens.push(obj);
                    }
                });

                this.item = resultado;

                if(this.item.embarcacaoId){

                    this.embarcacaoService.GetFoto(this.item.embarcacaoId).then(
                        res => {
                            this.item.embarcacao.foto = res.data;
                        },
                        err => AlertSimpleErr("Aviso!", err)
                    )
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() =>{
            this.overlay = false;
        });
        
        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res=>{
                this.empresa = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }
    
    Validacao(){
        if(this.item.atracacao.dataHoraEntrada != null && this.item.atracacao.dataHoraSaida != null){
            this.service.Validar(this.app.usuarioId,this.id)
                .then(res => {
                    if (res.status == 200){
                        return res.data;
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else{
            AlertSimple('Atenção!','Os campos Data/Hora Entrada e Data/Hora Saída precisam ser informados dentro do ROA para concluir a validação.','warning');
        }
    }

    Validar(item: RegistroOperacional){
        const context = this;
        const validar = function () {
            return new Promise( async function (resolve, reject){
                context.Validacao()
            });
        }
        AlertQuestion("Atenção!", "Tem certeza que deseja validar o registro atual?", validar);
    }
}
